import Vue from 'vue'
import App from './App.vue'
import 'vant/lib/index.css';

import { NavBar } from 'vant';
Vue.use(NavBar);

import { Button } from 'vant';
Vue.use(Button);

import { Col, Row } from 'vant';
Vue.use(Col);
Vue.use(Row);

import { Grid, GridItem } from 'vant';
Vue.use(Grid);
Vue.use(GridItem);

import { Image as VanImage } from 'vant';
Vue.use(VanImage);

import { Popup } from 'vant';
Vue.use(Popup);

import { Form } from 'vant';
import { Field } from 'vant';
Vue.use(Form);
Vue.use(Field);

import { Toast } from 'vant';
import router from './router'
import store from './store'
Vue.use(Toast);

document.title =  process.env.VUE_APP_TITLE;
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
