import Vue from 'vue'
import Vuex from 'vuex'
import { checkSmsAuthCode, payChannel, payFromOrderId } from "../utils/index"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    goods : { Icon:'', DisplayName:'', Price: 0 , DefineName:'' },
    userId : '',
    mobile : '',
    orderId : '',
    sign : '',
    action: '',
  },
  mutations: {
    SET_GOODS: (state, goods) =>{
      state.goods = goods;
    },
    SET_USERID: (state, userId) => {
      state.userId = userId;
    },
    SET_MOBILE: (state, mobile) => {
      state.mobile = mobile;
    },
    SET_ORDERID: (state, orderId) => {
      state.orderId = orderId;
    },
    SET_SIGN: (state, sign) => {
      state.sign = sign;
    },
    SET_ACTION: (state, action) => {
      state.action = action;
    },
  },
  actions: {
    checkGoods({ commit }, goods){
      commit('SET_GOODS', goods);
    },
    submitUserInfo({ commit }, from){
      var { userId, mobile, code } = from;
      return new Promise((resolve, reject) =>{
        checkSmsAuthCode(userId, mobile, code).then(_ =>{
          commit('SET_USERID', userId);
          commit('SET_MOBILE', mobile);
          resolve()
        }).catch(error =>{
          reject(error);
        })
      })
    },
    pay({ state,commit }, type){
      return new Promise((resolve, reject) =>{
        payChannel(state.userId, state.goods.DefineName, type).then(res =>{
          commit('SET_ORDERID', res.Data.OrderId);
          commit('SET_SIGN', res.Data.Sign);
          resolve(res)
        }).catch(error =>{
          reject(error);
        })
      })
    },
    payFromOrderId({ commit }, { action, orderId, sign}){
      commit('SET_ACTION', action );
      commit('SET_ORDERID', orderId );
      commit('SET_SIGN', sign );
      return new Promise((resolve, reject) =>{
        payFromOrderId(action, orderId, sign).then(res =>{
          resolve(res)
        }).catch(error =>{
          reject(error);
        })
      })
    }
  },
  modules: {
  }
})
