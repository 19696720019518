<template>
  <div class="contains">
    <GoodsList @goodsClick="goodsClick" />
    <GoodsCheck ref='goodsCheckPop'
                @goodsInfoEnter="goodsInfoEnter" />
    <UserInfo ref='userInfoPop'
              @showPayChannel="showPayChannel" />
    <PayChannel ref='payChannel'
                @showCheckPayResult="showCheckPayResult" />
    <CheckPayResult ref='checkPayResult'
                    @payFromOrderId="payFromOrderId"
                    @goHome="goHome" />
    <div id="alipay"></div>
  </div>
</template>

<script>
import GoodsList from "./components/GoodsList.vue"
import GoodsCheck from "./components/GoodsCheck.vue"
import UserInfo from "./components/UserInfo.vue"
import PayChannel from "./components/PayChannel.vue"
import CheckPayResult from "./components/CheckPayResult.vue"

export default {
  components: { GoodsList, GoodsCheck, UserInfo, PayChannel, CheckPayResult },
  name: 'Mall',
  data () {
    return {
    }
  },
  mounted () {
    var action = this.$route.params.action;
    var orderId = this.$route.params.orderId;
    var sign = this.$route.params.sign;
    this.$store.state.action = action;
    this.$store.state.orderId = orderId;
    this.$store.state.sign = sign;

    if (action === "gamepay") {
      this.payFromOrderId(action, orderId, sign);
    }
    if (action === "redirect") {
      this.showCheckPayResult();
    }
  },
  methods: {
    payFromOrderId (action, orderId, sign) {
      this.$store.dispatch('payFromOrderId', { action: action, orderId: orderId, sign: sign }).then(res => {
        if (res.Data.PayChannel === "WeiXin") {
          window.location.href = res.Data.Url;
        }
        if (res.Data.PayChannel === "AliPay") {
          var alipay = document.getElementById("alipay")
          alipay.innerHTML = res.Data.Url;
          document.forms['alipaysubmit'].submit();
        }
        this.showCheckPayResult();
      }).catch(_ => {
        setTimeout(this.goHome, 3 * 1000);
      })
    },
    goHome () {
      this.$router.push('/');
    },
    reset () {
      this.$refs['userInfoPop'].reset();
    },
    goodsClick () {
      this.reset();
      this.$refs['userInfoPop'].reset();
      this.$refs['goodsCheckPop'].showPop();
    },
    goodsInfoEnter () {
      this.$refs['goodsCheckPop'].closePop();
      this.$refs['userInfoPop'].showPop();
    },
    showPayChannel () {
      this.$refs['userInfoPop'].closePop();
      this.$refs['checkPayResult'].closePop();
      this.$refs['payChannel'].showPop();
    },
    showCheckPayResult () {
      this.$refs['payChannel'].closePop();
      this.$refs['checkPayResult'].showPop();
    }
  }
}
</script>

<style scoped>
.contains {
  background: #ffffff
    url("https://cdn.kxllx.wuyogames.com/PayGoodsIcons/bg-mobile.png") repeat;
  background-size: cover;
  height: 100vh;
  overflow-y: scroll;
}
</style>
