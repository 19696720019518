<template>
  <div class="lm-goodsList" >
    <div class="lm-goodInfo"  v-for="(item, index) in goodList" :key="index" @click="handleClick(item)">
      <img class="goodbackground" src="https://cdn.kxllx.wuyogames.com/PayGoodsIcons/goodbackground.png" alt="">
        <div class="info">
           <img class="goodImg" :src="item.IconUrl" alt="">
           <div class="goodName" >{{ item.DisplayName }}</div>
           <div class="rmb" >
             <img src="https://cdn.kxllx.wuyogames.com/PayGoodsIcons/rmb.png" alt="">
             <span class="price"> {{ item.Price / 100 }} </span>
           </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsList }  from "../../utils/index"

export default {
  name: 'GoodsList',
  data(){
    return {
      goodList:[],
    }
  },
  created(){
    getGoodsList().then(res =>{
      this.goodList = res.Data;
    })
  },
  methods:{
    handleClick(goods){
      this.$store.dispatch('checkGoods', goods)
      this.$emit('goodsClick')
    }
  }
}
</script>

<style scoped>
.lm-goodsList{
  margin: 20px 20px 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.lm-goodInfo{
  background:  transparent url("https://cdn.kxllx.wuyogames.com/PayGoodsIcons/goodbackground.png") no-repeat;
  background-size: contain;
  width: calc((100% - 20px) / 3);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-right: 10px;
  margin-bottom: 20px;
  position:relative;
}

.lm-goodInfo:nth-of-type(3n+0){margin-right: 0;} 

.goodbackground{
  visibility: hidden;
  position: relative;
  width: 100%;
  display: block;
}
.info{
  width: 100%;
  text-align: center;
  position:absolute;
  padding-top: 8%;
}
.goodImg{
  width: 60%;
}
.goodName{
  line-height: 7vw;
  font-size: 4vw;
  color: rgb(84,64,55);
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
}
.rmb{
  widows: 100%;
  vertical-align: middle;
}
.rmb img{
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}
.price{
  color:rgb(129,109,227);
  font-weight: 700;
  line-height: 7vw;
  font-size: 4vw;
  display: inline-block;
  vertical-align: middle;
  padding-right: 10%;
}
</style>
