<template>
  <van-popup v-model="show"
             round
             closeable
             :close-on-click-overlay="false"
             :style="{ width: '80%' }">

    <h3 class="form-title">请确认支付是否已完成</h3>

    <van-button type="default"
                class="checkPayBtn"
                :style="{ color:'#ee0a24' }"
                @click="handleQuery()">
      已完成支付
    </van-button>

    <van-button type="default"
                class="checkPayBtn"
                :style="{ color:'#455a64cc' }"
                @click="handleRePay()">
      支付遇到问题，重新支付
    </van-button>

  </van-popup>
</template>

<script>
import { orderQuery } from "../../utils/index"

export default {
  name: 'CheckPayResult',
  data () {
    return {
      show: false,
    }
  },

  methods: {
    showPop () {
      this.show = true;
    },
    closePop () {
      this.show = false;
    },
    handleClose () {
      this.show = false;
    },
    handleQuery () {
      orderQuery(this.$store.state.orderId).then(_ => {
      }).finally(_ => {
        this.show = false;
      })
    },
    handleRePay () {
      this.$emit('payFromOrderId', "redirect", this.$store.state.orderId, this.$store.state.sign);
    }
  }
}
</script>

<style scoped>
.form-title {
  margin: 0;
  padding: 16px 0 0 0;
  color: #323233;
  font-weight: 600;
  font-size: 17px;
  line-height: 50px;
  text-align: center;
}
.checkPayBtn {
  font-weight: 600;
  font-size: 15px;
  width: 100%;
}

@font-face {
  font-family: "iconfont"; /* Project id 2804310 */
  src: url("//at.alicdn.com/t/font_2804310_zx0ikzjylxl.woff2?t=1638933351349")
      format("woff2"),
    url("//at.alicdn.com/t/font_2804310_zx0ikzjylxl.woff?t=1638933351349")
      format("woff"),
    url("//at.alicdn.com/t/font_2804310_zx0ikzjylxl.ttf?t=1638933351349")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-icon-wechat:before {
  content: "\e61a";
}

.el-icon-alipay:before {
  content: "\e607";
}
</style>
