<template>
  <van-popup v-model="show" round closeable  :style="{ width: '80%', padding:'0 0 20px 0' }">
     
    <h3 class="form-title">请选择支付方式</h3>

    <van-row type="flex" justify="center" :style="{ margin: '10px' }">
      <van-col >   
        <van-button round class-prefix="iconfont"   icon="wechat" type="primary" size="large" :style="{ width: '150px' }" @click="handlePay(0)">
        微信支付
        </van-button>
      </van-col>
    </van-row>

    <van-row type="flex" justify="center" :style="{ margin: '10px' }">
      <van-col >   
        <van-button round  class-prefix="iconfont"   icon="alipay" type="info" size="large" :style="{ width: '150px' }" @click="handlePay(1)">
        支付宝
        </van-button>
      </van-col>
    </van-row>

  </van-popup>
</template>

<script>
export default {
  name: 'PayChannel',
  data(){
    return {
      show:false
    }
  },
  methods:{
    showPop(){
      this.show = true;
    },
    closePop(){
      this.show = false;
    },
    handleClose(){
      this.show = false;
    },
    handlePay(type){
      this.$store.dispatch('pay',type).then(res =>{
        this.$emit("showCheckPayResult");
        if(res.Data.PayChannel === "WeiXin"){
          window.location.href = res.Data.Url;
        }
        if(res.Data.PayChannel === "AliPay"){
          var alipay = document.getElementById("alipay")
          alipay.innerHTML = res.Data.Url;
          document.forms['alipaysubmit'].submit();
        }
      })
    }
  }
}
</script>

<style scoped>
.form-title{
  margin: 0;
  padding: 16px 16px;
  color: #323233;
  font-weight: 600;
  font-size: 17px;
  line-height: 16px;
}

@font-face {
  font-family: "iconfont"; /* Project id 2804310 */
  src: url('//at.alicdn.com/t/font_2804310_zx0ikzjylxl.woff2?t=1638933351349') format('woff2'),
       url('//at.alicdn.com/t/font_2804310_zx0ikzjylxl.woff?t=1638933351349') format('woff'),
       url('//at.alicdn.com/t/font_2804310_zx0ikzjylxl.ttf?t=1638933351349') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-icon-wechat:before {
  content: "\e61a";
}

.el-icon-alipay:before {
  content: "\e607";
}
</style>
