import axios from 'axios';
import qs from "querystring"
import { Toast } from 'vant';

const toastOption = {
  message: '加载中...',
  forbidClick: true,
};

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 300 
});

service.interceptors.request.use(
  config => {
    if (config.method === 'post' &&
    config.headers['Content-Type'] === undefined &&
    config.data !== undefined) {
    const data = qs.stringify(config.data);
    config.data = data;
  }

    if(!config.notLoading){
      Toast.loading(toastOption);
    }
    return config;
  },
  error => {
    console.log(error); 
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const config = response.config;
    const res = response.data;
    Toast.clear();
    if (res.ErrorCode === undefined) {
      Toast.fail("Undefined");
    }
    const code = res.ErrorCode;
    if (code === 1) {
      if (response.config.method === 'post' || response.config.method === 'delete') {
        if(!config.notRspToast){
          Toast.success('成功');
        }
      }
      return res;
    } else {
      if(!config.notRspToast){
        Toast.fail(res.Message || res.Value.Message || 'Error');
      }
      // return Promise.reject(new Error(res.Message || 'Error'))
      return Promise.reject(res);
    }
  },
  error => {
    console.log('err' + error); // for debug
    Toast.fail(error.Message );
    return Promise.reject(error);
  }
);

export default service;
