<template>
<van-popup v-model="show" round  @close="handleClose" :style="{ width: '80%' }">
  <h3 class="form-title">请确认购买的商品</h3>
  <div class="lm-goodsList" >
      <div class="lm-goodInfo" >
        <img class="goodbackground" src="https://cdn.kxllx.wuyogames.com/PayGoodsIcons/goodbackground.png" alt="">
          <div class="info">
             <img class="goodImg" :src="goods.IconUrl" alt="">
             <div class="goodName" >{{ goods.DisplayName }}</div>
             <div class="rmb" >
               <img src="https://cdn.kxllx.wuyogames.com/PayGoodsIcons/rmb.png" alt="">
               <span class="price"> {{ goods.Price / 100 }} </span>
             </div>
        </div>
      </div>
  </div>

  <van-row type="flex" justify="center" :style="{ margin: '10px' }" :gutter="20">
    <van-col >   
      <van-button round  type="info"  :style="{ width: '100px' }" @click="handleEnter">
      确认
      </van-button>
    </van-col>
    <van-col >   
      <van-button round  type="default"  :style="{ width: '100px' }" @click="handleClose">
      取消
      </van-button>
    </van-col>
  </van-row>
 </van-popup>

</template>

<script>

export default {
  name: 'GoodsCheck',
  data(){
    return {
      show:false,
      goods : { Icon:'', DisplayName:'', Price: 0 , DefineName:'' },
    }
  },
  methods:{
    showPop(){
      this.goods = this.$store.state.goods;
      this.show = true;
    },
    closePop(){
      this.show = false;
    },
    handleEnter(){
      this.$emit('goodsInfoEnter')
    },
    handleClose(){
      this.show = false;
    }
  }
}
</script>

<style scoped>
.form-title{
  margin: 0;
  padding: 16px 16px;
  color: #323233;
  font-weight: 600;
  font-size: 17px;
  line-height: 16px;
}
.lm-goodsList{
  margin: 20px 20px 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.lm-goodInfo{
  background:  transparent url("https://cdn.kxllx.wuyogames.com/PayGoodsIcons/goodbackground.png") no-repeat;
  background-size: contain;
  width: 50%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-right: 10px;
  margin-bottom: 20px;
  position:relative;
}
.goodbackground{
  visibility: hidden;
  position: relative;
  width: 100%;
  display: block;
}
.info{
  width: 100%;
  text-align: center;
  position: absolute;
  padding-top: 8%;
}
.goodImg{
  width: 60%;
}
.goodName{
  color: rgb(84,64,55);
  line-height: 8vw;
  font-size: 4vw;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
}
.rmb{
  vertical-align: middle;
}
.rmb img{
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}
.price{
  color:rgb(129,109,227);
  line-height: 12vw;
  font-size: 5vw;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
  padding-right: 10%;
}
</style>
