import request from "./request"

export function getGoodsList() {
  return request({
    url: '/Pay/GetGoodsList',
    method: 'get',
  });
}

export function sendSms({userId, mobile}) {
  return request({
    url: '/Pay/SendSms',
    method: 'post',
    notLoading:true,
    data:{
      userId,mobile
    }
  });
}

export function checkSmsAuthCode(userId, mobile, code) {
  return request({
    url: '/Pay/PostCheckSmsAuthCode',
    method: 'post',
    data:{
      userId, mobile, code
    }
  });
}

export function payChannel(userId,defineName, type) {
  return request({
    url: '/Pay/PayChannel',
    method: 'post',
    notRspToast:true,
    data:{
      userId, defineName, type
    }
  });
}

export function payFromOrderId(action, orderId, sign) {
  return request({
    url: '/Pay/PayFromOrder',
    method: 'post',
    data:{
      action, orderId, sign
    }
  });
}

export function orderQuery(orderId) {
  return request({
    url: '/Pay/OrderQuery',
    method: 'post',
    notRspToast:true,
    data:{
       orderId
    }
  });
}

