<template>
  <van-popup v-model="show" round closeable position="bottom" >
    <div>
      <h3 class="form-title">填写玩家信息</h3>
      <van-form ref="userInfoForm">
        <van-field v-model="form.userId" name="userId" type="digit" label="用户ID"  placeholder="用户ID"  :rules="[{ required: true, message: '请填写用户ID' }]" />
        <van-field v-model="form.mobile" name="mobile" type="tel" label="手机号"  placeholder="手机号" :rules="[{ required: true, message: '请填写手机号' }]" />
        <van-field
        v-model="form.code"
        name="code"
        type="digit"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <van-button size="small" native-type="button" v-show="!isSend" type="primary" @click="handleSendSms">发送验证码</van-button>
            <van-button size="small" native-type="button" v-show="isSend" disabled  type="primary" @click="handleSendSms">{{ interval }}秒后重试</van-button>
          </template>
        </van-field>

        <div style="margin: 16px;">
          <van-button round block type="info"  @click="onSubmit" >提交</van-button>
        </div>
      </van-form>

    </div>
  </van-popup>
</template>

<script>
import { sendSms } from "../../utils/index"

export default {
  name: 'GoodsCheck',
  props:{ 
    goodsInfo:{ type:Object,
    default: function() {
      return { Icon:'', DisplayName:'', Price: 0 , DefineName:''}
      } 
    }
  },
  data(){
    return {
      show:false,
      form:{},
      isSend:false,
      interval:60,
      timer :'',
    }
  },
  methods:{
    reset(){
      this.form = {}
    },
    showPop(){
      this.show = true;
    },
    closePop(){
      this.show = false;
    },
    handleSendSms(){
      this.$refs['userInfoForm'].validate(["userId","mobile"]).then(_ =>{
         return sendSms(this.form);
      },_ =>{
         return Promise.reject('reject');
      })
      .then(res =>{
        if(res.Data === 'Success'){
          this.isSend = true;
          this.timer = setInterval(this.setIntervalFunc,1000);
          setTimeout(this.setTimeoutFunc,60 * 1000);
        }
      })
    },
    setIntervalFunc(){
      this.interval -- ;
      console.log(this.interval);
    },
    setTimeoutFunc(){
      this.isSend = false;
      this.interval = 60;
      clearInterval(this.timer);
    },
    onSubmit(){
      this.$refs['userInfoForm'].validate().then(_ =>{
         this.$store.dispatch('submitUserInfo',this.form ).then(_ =>{
            this.$emit('showPayChannel');
         })
      })
    }
  }
}
</script>

<style scoped>
.form-title{
  margin: 0;
  padding: 16px 16px;
  color: #323233;
  font-weight: 600;
  font-size: 17px;
  line-height: 16px;
}
</style>
